import {Inject, Injectable} from '@angular/core';
import * as Prismic from '@prismicio/client';
import {asHTML, asText} from '@prismicio/helpers';
import {Environment} from '../environment/environment.token';
import {PrismicDoc} from "../../../types/app-types";

@Injectable({
  providedIn: 'root'
})
export class PrismicService {
  private api: Prismic.Client;
  private content = [];
  private loaded: Promise<boolean>;

  constructor(
    @Inject(Environment) private environment: any
  ) {
    this.api = Prismic.createClient(this.environment.prismicEndpoint, { accessToken: this.environment.prismicApiToken });
  }

  public load() {
    this.loaded = this.loadAllDocuments(100);
  }

  private loadAllDocuments(pageSize: number): Promise<boolean> {
    return new Promise(resolve => {
      this.api.get(
        {
          predicates: [Prismic.predicate.any('document.tags', this.environment.prismicTags)],
          pageSize: pageSize
        }
      ).then(response => {
        this.content.push(...response.results);
        resolve(true);
      });
    });
  }

  public getDocumentByType<DocType = any>(type: string): Promise<PrismicDoc<DocType>> {
    return new Promise(resolve => {
      this.loaded.then(() => {
        resolve(this.content.find(it => it.type === type));
      });
    });
  }

  public getDocumentsByType<DocType = any>(type: string): Promise<Array<PrismicDoc<DocType>>> {
    return new Promise(resolve => {
      this.loaded.then(() => {
        resolve(this.content.filter(it => it.type === type));
      });
    });
  }

  public getDocumentByUid<DocType = unknown>(type: string, uid: string): Promise<{ data: DocType }> {
    return new Promise(resolve => {
      this.loaded.then(() => {
        resolve(this.content.find(it => it.type === type && it.uid === uid));
      });
    });
  }

  public convertToHtml(data: any): string {
    return asHTML(data);
  }

  public convertToText(data: any): string {
    return asText(data);
  }
}

const htmlSerializer = {
  // Arguments can be destructured for each block type
  paragraph: ({ children }) => `<p class="paragraph-class">${children}</p>`
}
