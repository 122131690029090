import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TestResult, Survey, Dialog } from "../../../types/app-types";

@Injectable({
  providedIn: 'root'
})
export class SurveyResource {

  public constructor(private httpClient: HttpClient) {
  }

  public getFirstSurveyQuestion(surveyId: string): Observable<any> {
    return this.httpClient.post('/testengine/dialog/survey/' + surveyId + '/first', {});
  }

  public getNextSurveyQuestion(questionPathId: string, metaData: any, answer: any, dialogId: string): Observable<any> {
    return this.httpClient.post('/testengine/dialog/' + dialogId + '/' + questionPathId + '/next', {
      answer
    });
  }

  public getFirstQuestionOfDialog(dialogId: string): Observable<any> {
    return this.httpClient.get('/testengine/dialog/' + dialogId + '/first');
  }

  public getPreviousSurveyQuestion(questionPathId: string, metaData: any, dialogId: string, answer?: any): Observable<any> {
    const body = answer ? { answer } : {};
    return this.httpClient.post('/testengine/dialog/' + dialogId + '/' + questionPathId + '/previous', body);
  }

  public getAmountOfTestsInSurvey(surveyId: string): Observable<any> {
    return this.httpClient.get('/testengine/tests/' + surveyId + '/amount-of-questions');
  }

  public getSurvey(surveyId: string): Observable<Survey> {
    return this.httpClient.get<Survey>('/testengine/tests/' + surveyId);
  }

  public getTests(params?: any): Observable<any> {
    return this.httpClient.get('/testengine/tests/search', { params });
  }

  public getTestResult(dialogId: number): Observable<TestResult> {
    return this.httpClient.get<TestResult>('/testengine/result/' + dialogId);
  }

  public getTestWithPrismicId(prismicId: string): Observable<any> {
    return this.httpClient.get('/testengine/tests/prismicId/' + prismicId);
  }

  public getCompletedSurveys(): Observable<Dialog[]> {
    return this.httpClient.get<Dialog[]>('/testengine/dialog');
  }

  public deleteTestResult(dialogId: any): Observable<any> {
    return this.httpClient.delete<any>(`/testengine/dialog/${dialogId}`);
  }
}
