import { HttpMethodEnum } from './HttpMethodEnum';

export class ExcludedCall {
  startsWithPath: string;
  method: HttpMethodEnum;

  public constructor(path: string, method: HttpMethodEnum) {
    this.startsWithPath = path;
    this.method = method;
  }

  public matches = (path: string, method: string): boolean => {
    return path.indexOf(this.startsWithPath) === 0 && HttpMethodEnum[method] === this.method;
  };
}
