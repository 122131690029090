import { NgModule } from '@angular/core';
import { InputComponent } from './input.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../app.material.module';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, MaterialModule, RouterModule, ReactiveFormsModule],
  exports: [InputComponent]
})
export class InputModule {}
