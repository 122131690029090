import {Routes} from '@angular/router';
import {AboutComponent} from './pages/about/about.component';
import {AuthGuardService as AuthGuard} from "./services/auth-guard.service";
import {NotFoundPageComponent} from './pages/page-not-found/not-found-page.component';

export const ROUTES: Routes = [

  {
    path: 'functies',
    data: {
      title: 'Functies in de Zorg | Zorginspirator',
      description: 'Ontdek welke functies er in de zorg voor jou zijn! Op deze pagina van Zorginspirator zoek je en vind je meer dan vierhonderd zorgfuncties van A tot Z beschreven. Zorgbreed, van verpleging, verzorging en begeleiding tot aan projectleiding, inkoop, accountmanagement, planning of beveiliging.',
      keywords: 'Functies in de Zorg, Zorgfuncties, Functies in zorg en welzijn, Functies in Zorginspirator'
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/functie/functie-overzicht/functie-overzicht-page.module').then(
        module => module.FunctieOverzichtPageModule
      )
  },
  {
    path: 'functie/:slug',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/functie/functie-detail/function-detail-page.module').then(
        module => module.FunctionDetailPageModule
      )
  },
  {
    path: 'test/:surveyId',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/generic-test/generic-test.module').then(
        module => module.GenericTestModule
      )
  },
  {
    path: 'test-overzicht',
    data: {
      title: 'Gratis loopbaantesten in Zorginspirator',
      description: 'Doe onze tests en ontdek meer over jezelf en het werken in de Zorg! Op deze pagina van Zorginspirator vind je verschillende testen. Doe bijvoorbeeld de energiegevers test en ontdek wat jou in je werk energie geeft. Of vul de werkstijlen test in om je persoonlijke werkstijl te bepalen en te ontdekken welke functies in de zorg bij jouw werkstijlen passen.',
      keywords: 'Test jezelf in de Zorg, Loopbaantest, Zorginspirator test, persoonlijkheidstest, competentietest, beroepskeuzetest'
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/test/test-page.module').then(
        module => module.TestPageModule
      )
  },
  {
    path: 'opleidingen',
    data: {
      title: 'Opleidingen in de Zorg | Zorginspirator',
      description: 'Op deze pagina van Zorginspirator vind je een rijk aanbod van opleidingen in de zorg. Van beroepsopleidingen en specialisaties tot voorschakeltrajecten en cursussen. Zorgbreed, van verpleegkunde, verzorging, maatschappelijke- of operatieve zorg tot aan specifieke scholing voor je BIG herregistratie of op het gebied van communicatie, administratie of management.',
      keywords: 'Opleidingen in de Zorg, Zorgopleidingen, Opleidingen in zorg en welzijn, Opleidingen in Zorginspirator'
    },
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/opleiding/opleiding-overzicht/opleiding-overzicht-page.module').then(
        module => module.OpleidingOverzichtPageModule
      )
  },
  {
    path: 'opleiding/:slug',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/opleiding/opleiding-detail/opleiding-detail-page.module').then(
        module => module.OpleidingDetailPageModule
      )
  },
  {
    path: 'opleidingopbouw',
    canActivate: [AuthGuard],
    data: {
      isChatFlow: true
    },
    loadChildren: () =>
      import('./pages/opleiding/opleiding-opbouw/opleiding-opbouw.module').then(module => module.OpleidingOpbouwModule)
  },
  {
    path: 'profiel',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profiel/overzicht/profiel-overzicht-page.module').then(
        module => module.ProfielOverzichtPageModule
      )
  },
  {
    path: 'profielopbouw',
    canActivate: [AuthGuard],
    data: {
      isChatFlow: true
    },
    loadChildren: () =>
      import('./pages/profiel/opbouw/profiel-opbouw-page.module').then(module => module.ProfielOpbouwPageModule)
  },
  {
    path: 'profiel-print',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profiel/print/profiel-print-page.module').then(module => module.ProfielPrintPageModule)
  },
  {
    path: 'opleiding-inspirator',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/opleiding-inspirator/opleiding-inspirator-page.module').then(
        module => module.OpleidingInspiratorPageModule
      )
  },
  {
    path: 'functietoekomstopbouw',
    canActivate: [AuthGuard],
    data: {
      isChatFlow: true
    },
    loadChildren: () =>
      import('./pages/functie/functie-toekomst-opbouw/functie-toekomst-opbouw-page.module').then(
        module => module.FunctieToekomstOpbouwPageModule
      )
  },
  {
    path: 'werkervaringopbouw',
    canActivate: [AuthGuard],
    data: {
      isChatFlow: true
    },
    loadChildren: () =>
      import('./pages/functie/functie-werkervaring-opbouw/functie-werkervaring-opbouw.module').then(
        module => module.FunctieWerkervaringOpbouwModule
      )
  },
  {
    path: 'resultaat',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profiel/resultaat/resultaat-page.module').then(module => module.ResultaatPageModule)
  },
  {
    path: 'account-aanmaken',
    data: {
      title: 'Account aanmaken'
    },
    loadChildren: () => import('./pages/account/register/register.module').then(module => module.RegisterModule)
  },
  {
    path: 'wachtwoord-vergeten',
    data: {
      title: 'Wachtwoord vergeten'
    },
    loadChildren: () =>
      import('./pages/account/password-forgotten/password-forgotten.module').then(
        module => module.PasswordForgottenModule
      )
  },
  {
    path: 'wachtwoord-veranderen',
    data: {
      title: 'Wachtwoord veranderen'
    },
    loadChildren: () =>
      import('./pages/account/password-change/password-change.module').then(module => module.PasswordChangeModule)
  },
  {
    path: 'rekenhulp',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/rekenhulp/rekenhulp-page.module').then(module => module.RekenhulpPageModule)
  },
  {
    path: 'over-zorginspirator',
    data: {
      title: 'Over zorginspirator'
    },
    component: AboutComponent
  },
  {
    path: 'landing-test',
    loadChildren: () => import('./pages/landing-test/landing-test.module').then(module => module.LandingTestModule)
  },
  {
    path: 'info',
    data: {
      title: 'Informatie'
    },
    loadChildren: () => import('./pages/info/info-page.module').then(module => module.InfoPageModule)
  },
  {
    path: 'compare',
    loadChildren: () => import('./pages/compare/compare-page.module').then(module => module.ComparePageModule)
  },
  // {
  //   path: 'vergelijk/profiel/functie/:id',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./pages/vergelijk/profiel-functie/vergelijk-profiel-functie-page.module').then(
  //       module => module.VergelijkProfielFunctiePageModule
  //     )
  // },
  {
    path: 'pop',
    canActivate: [AuthGuard],
    data: {
      isRestricted: true,
    },
    loadChildren: () => import('./pages/pop/pop-page.module').then(module => module.PopPageModule)
  },
  {
    path: 'pop-view/:id',
    loadChildren: () => import('./pages/pop/view/pop-view-page.module').then(module => module.PopViewPageModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard-page.module').then(module => module.DashboardPageModule)
  },
  {
    path: 'dashboard/linkedin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard-page.module').then(module => module.DashboardPageModule)
  },
  {
    path: 'dashboard/facebook',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard-page.module').then(module => module.DashboardPageModule)
  },
  {
    path: '',
    data: {
      title: 'Zorginspirator',
      description: 'Zorginspirator.nl is een onafhankelijk online platform van de zorgsector, speciaal voor mensen die werk willen maken van hun toekomst in de zorg. Hier krijg je onafhankelijk inzicht in jouw persoonlijke werkperspectieven. En in alle functies van de toekomst. Inclusief koppelingen naar opleidingen en vacatures.',
      keywords: 'zorg, inspirator, zorginspirator, zorgsector, toekomst, functie, functies, opleiding, opleidingen, vacature, werkperspectief'
    },
    loadChildren: () => import('./pages/home/home-page.module').then(module => module.HomePageModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundPageComponent
  }
];
