<nav class="navigation__container" #navigation *ngIf="activeRoute" [ngClass]="{maximized: !isMinimized}">
  <ul class="navigation">
    <ng-container *ngFor="let item of cmsContent">
      <li
        *ngIf="item.visible"
        class="navigation__item"
        [ngClass]="{
        'navigation__item--minimized': isMinimized,
        'navigation__item--active': item.isActiveRoute(activeRoute),
        'navigation__item--visible': !isMinimized || (item.type !== 'Internal' && item.type !== 'External')
      }"
        [attr.data-type]="item.type"
      >
        <a
          *ngIf="item.type !== 'External'"
          [matTooltip]="item.label"
          [routerLink]="item.link"
          (click)="minimizeMenu()"
          target="{{item.targetBlank ? '_blank': '_self'}}"
          [attr.data-cy]="item.type.replace(' ','')+'MenuItem'"
        >
          <mat-icon *ngIf="item.icon?.type === 'font'" class="navigation__item__icon"
                    inline>{{item.icon.name}}</mat-icon>
          <img *ngIf="item.icon?.type === 'svg'" class="svg-icon" src="{{item.icon?.name}}" alt="leeg"/>
          <div *ngIf="!isMinimized || screenMode === 'DESKTOP_EXTRA_LARGE'">
            <h4>
              {{item.label}}
            </h4>
            <h6 *ngIf="item.type === 'Mijn gegevens'">
              {{ profileSubTitle | content}}
            </h6>
          </div>
        </a>
        <a
          *ngIf="item.type === 'External'"
          [matTooltip]="item.label"
          [href]="item.link"
          (click)="minimizeMenu()"
          target="{{item.targetBlank ? '_blank': '_self'}}"
          [attr.data-cy]="item.type.replace(' ','')+'MenuItem'"
        >
          <mat-icon *ngIf="item.icon?.type === 'font'" class="navigation__item__icon"
                    inline>{{item.icon.name}}</mat-icon>
          <img *ngIf="item.icon?.type === 'svg'" class="svg-icon" src="{{item.icon?.name}}" alt="leeg"/>
          <div *ngIf="!isMinimized || screenMode === 'DESKTOP_EXTRA_LARGE'">
            <h4>
              {{item.label}}
            </h4>
            <h6 *ngIf="item.type === 'Mijn gegevens'">
              {{ profileSubTitle | content}}
            </h6>
          </div>
        </a>
        <button *ngIf="item.type === 'Mijn gegevens' && (!isMinimized || screenMode === 'DESKTOP_EXTRA_LARGE')"
                class="navigation__item__link"
        (click)="handleAuthentication($event)">
          <mat-icon inline>exit_to_app</mat-icon>
          <span>{{ authenticateButtonContentKey | content }}</span>
        </button>
      </li>
    </ng-container>
  </ul>
</nav>
