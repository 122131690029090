import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContentModule} from '../../services/content/content.module';
import {IeWarningModalComponent} from './ie-warning-modal.component';
import {MaterialModule} from '../../app.material.module';

@NgModule({
    declarations: [IeWarningModalComponent],
    imports: [CommonModule, MaterialModule, ContentModule],
    exports: [IeWarningModalComponent]
})
export class IeWarningModule {}
