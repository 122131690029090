import {Component, OnInit} from '@angular/core';
import { PrismicService } from "../../services/prismic/prismic.service";
import {PrismicAboutPage} from "../../../types/app-types";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public cmsContent: AboutContent

  public constructor(
    private prismic: PrismicService
  ) {
  }

  public ngOnInit() {
    this.prismic.getDocumentByType<PrismicAboutPage>("aboutpage").then( doc => {
      this.cmsContent = this.transform(doc.data)
    });
  }

  private transform(doc: PrismicAboutPage): AboutContent {
    return <AboutContent> {
      title: this.prismic.convertToText(doc.title),
      introduction: this.prismic.convertToText(doc.introduction),
      // movie: doc.movie.html,
      body: this.prismic.convertToHtml(doc.body)
    }
  }
}

export interface AboutContent {
  title: string,
  introduction: string,
  // movie: any
  body: any
}
