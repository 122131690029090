import { Component, OnDestroy, OnInit } from '@angular/core';
import { UtilService } from './services/util/util.service';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { ScreenMode } from './services/util/screen-mode.enum';
import { AnalyticsService } from './services/analytics/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { IeWarningModalComponent } from './components/ie-warning-modal/ie-warning-modal.component';
import { SeoService } from './services/seo.service';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public pageTitle = '';

  public mode: string = 'desktop';
  public showNavigation: boolean = false;

  public isMinimized: boolean = true;
  public isSmallerMobile: boolean = false;

  public direction: string = 'up';
  public subscription: Subscription;

  public constructor(
    private utilService: UtilService,
    private analyticsService: AnalyticsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private meta: Meta,
    private matDialog: MatDialog,
    private seoService: SeoService
  ) {
    this.utilService.getScreenMode$().subscribe((mode: string) => {
      this.mode = mode;
      this.isSmallerMobile = this.mode === ScreenMode.MOBILE_SMALL || this.mode === ScreenMode.MOBILE_MEDIUM;

      if (this.mode === ScreenMode.DESKTOP_EXTRA_LARGE) {
        this.isMinimized = false;
      }
    });

    if (navigator.userAgent.indexOf('MSIE') > 0 || navigator.userAgent.indexOf('Trident') > 0) {
      matDialog.open(IeWarningModalComponent);
    }

    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.showNavigation = e.url !== '/';
        this.analyticsService.pageView();
      }
    });

    utilService.getDirection$().subscribe(scrollDirection => {
      this.direction = scrollDirection;
    });
  }

  public ngOnInit(): void {
    // SEO
    this.subscription = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      tap((data: Data) => {
        // Set Title and Description
        this.seoService.setDescription(data.description);
        this.seoService.setPageTitle(data.title);
        this.seoService.setKeywords(data.keywords);

        // Set Canonical URL
        this.seoService.createLinkForCanonicalURL();

        // Set Meta tags
        if (data.robots) {
          this.meta.updateTag({ name: 'robots', content: data.robots });
        } else {
          this.meta.updateTag({ name: 'robots', content: 'follow,index' });
        }

        if (data.ogUrl) {
          this.meta.updateTag({ property: 'og:url', content: data.ogUrl });
        } else {
          this.meta.updateTag({ property: 'og:url', content: this.router.url });
        }

        if (data.ogTitle) {
          this.meta.updateTag({ property: 'og:title', content: data.ogTitle });
        } else {
          this.meta.removeTag('property=\'og:title\'');
        }

        if (data.ogDescription) {
          this.meta.updateTag({ property: 'og:description', content: data.ogDescription });
        } else {
          this.meta.removeTag('property=\'og:description\'');
        }

        if (data.ogImage) {
          this.meta.updateTag({ property: 'og:image', content: data.ogImage });
        } else {
          this.meta.removeTag('property=\'og:image\'');
        }
      })
    ).subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setIsMinimized(): void {
    this.isMinimized = true;
  }

  public toggleIsMinimized(): void {
    this.isMinimized = !this.isMinimized;
  }
}
