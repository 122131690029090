import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { UtilService } from '../../services/util/util.service';
import { ContentService } from '../../services/content/content.service';
import { ScreenMode } from '../../services/util/screen-mode.enum';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ProfileStorageService } from '../../services/profile/profile-storage.service';
import { ProfielResource } from '../../services/resources/profiel.resource';
import { Profiel } from '../../model/profiel/profiel';
import { AccountResource } from '../../services/resources/account.resources';
import {Location} from "@angular/common";

const content = require('./header.content.json');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy {
  @Input()
  public isMinimized: boolean;

  @Input()
  public showNavigation: boolean = false;

  @Output()
  public hamburgerClick: EventEmitter<void> = new EventEmitter<void>();

  public subscriptions: Subscription[] = [];
  public screenMode: string;

  public direction: string = 'up';

  public isMobile: boolean = false;
  public isChatFlow: boolean = false;
  public isLoggedIn: boolean = false;
  public isSmallerMobile: boolean = false;

  public userFirstName: string;

  public readonly verticalHeaderMinimizedWidth = 80;
  public readonly verticalHeaderMaximizedWidth = 320;
  public readonly contentWithOverflowHeaderWidth = 1190;
  public readonly contentMaxWidth = 1430;

  public constructor(
    private router: Router,
    private location: Location,
    private utilService: UtilService,
    private contentService: ContentService,
    private profileStorageService: ProfileStorageService,
    private profielResource: ProfielResource,
    private accountResource: AccountResource,
    private matDialog: MatDialog
  ) {
    contentService.loadContent(content);

    this.subscriptions.push(
      this.profileStorageService.loggedInSubject.subscribe((isLoggedIn: boolean) => {
        if (isLoggedIn) {
          this.retrieveProfielNaam();
        }

        this.isLoggedIn = isLoggedIn;
      })
    );

    this.subscriptions.push(
      this.profileStorageService.profileIsChangedSubject.subscribe(() => {
        this.retrieveProfielNaam();
      })
    );

    this.subscriptions.push(
      router.events.subscribe(event => {
        if (event instanceof RoutesRecognized) {
          const currentRoute = event.state.root.firstChild;
          this.isChatFlow = currentRoute.data && !!currentRoute.data['isChatFlow'];
        }
      })
    );

    this.subscriptions.push(
      utilService.getDirection$().subscribe((scrollDirection: string) => {
        this.direction = scrollDirection;
      })
    );

    this.subscriptions.push(
      utilService.getScreenMode$().subscribe((screenMode: string) => {
        this.screenMode = screenMode;
        this.isMobile = this.screenMode.includes('MOBILE');
        this.isSmallerMobile =
          this.screenMode === ScreenMode.MOBILE_SMALL || this.screenMode === ScreenMode.MOBILE_MEDIUM;
      })
    );
  }

  private retrieveProfielNaam() {
    this.profielResource.getProfiel().subscribe((profiel: Profiel) => {
      this.userFirstName = profiel ? profiel.naam : '';
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public handleHamburgerClick(): void {
    this.hamburgerClick.emit();
  }

  public handleAuthentication(): void {
    if (!this.isLoggedIn) {
      this.openLoginModal();
    } else {
      this.logout();
    }
  }

  private async openLoginModal(): Promise<void> {
    const { LoginModalComponent } = await import('../account/login-modal/login-modal.component');
    const config: MatDialogConfig = this.isMobile
      ? {
          height: '100%',
          width: '100%',
          maxHeight: '100%',
          maxWidth: '100%'
        }
      : {
          width: '380px',
          maxWidth: '100%'
        };

    const ref: MatDialogRef<any> = this.matDialog.open(LoginModalComponent, config);
    ref.componentInstance.rerouteEnabled = true;
  }

  private logout() {
    this.profileStorageService.deleteProfileStorage();
    this.router.navigate(['/']);
    this.accountResource.getLogout().subscribe(() => {
      this.userFirstName = '';
    });
  }

  public goBack() {
    this.location.back();
  }
}
