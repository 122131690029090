import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DataContainer, Functie} from '../../model';
import {convertToHttpParams} from '../../helpers/HttpParamsConverter';
import {ProfileMatchView} from "../../model/profiel/profiel";
import {ProfileMatchSelectionTypeEnum} from "../util/profile-match-selection-type.enum";

const api = '/api/';

@Injectable({
  providedIn: 'root'
})
export class FunctieResource {
  public constructor(private httpClient: HttpClient) {
  }

  public getFunctie(id: any): Observable<Functie> {
    return this.httpClient.get<Functie>(api + 'functies/' + id);
  }

  public zoekFuncties(options: any): Observable<DataContainer<Functie>> {
    return this.httpClient.get<DataContainer<Functie>>(api + 'functies', { params: convertToHttpParams(options) });
  }

  public zoekFunctiesEnMatchMetProfielSelectie(options: any, profileMatchSelection: ProfileMatchView, profileMatchSelectionType: ProfileMatchSelectionTypeEnum): Observable<DataContainer<Functie>> {
    return this.httpClient.post<DataContainer<Functie>>(api + 'functies', {
      profielMatchSelectieType: profileMatchSelectionType,
      profiel: profileMatchSelection
    },{ params: convertToHttpParams(options) });
  }

  public getVerwanteFuncties(id: string, excludeRequestId: boolean = false): Observable<any> {
    return this.httpClient.get(`${api}gerelateerde-functies/${id}?excludeRequestId=${excludeRequestId}`);
  }

  public getVervolgFuncties(id: string): Observable<any> {
    return this.httpClient.get(api + 'functies/' + id + '/vervolgfuncties');
  }

  public getVerwanteFunctiesForProfiel(profielWrapper: any): Observable<Functie[]> {
    return this.httpClient.post<Functie[]>(api + 'gerelateerde-functies/profiel?minimumMatchPercentage=10', profielWrapper);
  }

  public getFunctieFilters(): Observable<any> {
    return this.httpClient.get(api + 'filters');
  }

  public compareFuncties(sourceId: string, destinationId: string): Observable<any> {
    return this.httpClient.get(api + 'functies/vergelijk/' + sourceId + '/' + destinationId);
  }

  public getOpleidingForFunction(id: string): Observable<any> {
    return this.httpClient.get(api + 'functies/' + id + '/opleidingen');
  }
}
