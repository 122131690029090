import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { BehaviorSubject, Subject } from "rxjs";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class ProfileStorageService {
  static profileStorageKey: string = "lbp-profile";

  private isLoggedIn: boolean = false;

  public loggedInSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(this.isLoggedIn);
  public profileIsChangedSubject: Subject<void> = new Subject<void>();

  public constructor(
    private storageService: StorageService,
    private jwtHelper: JwtHelperService
  ) {
    this.changeIsLoggedInWithToken();
  }

  public deleteProfileStorage(): void {
    this.storageService.delete(ProfileStorageService.profileStorageKey);

    this.changeIsLoggedInWithToken();
    this.toggleProfileIsChanged();
  }

  public saveProfileInStorage(profile: any): void {
    const currentProfile = this.storageService.get(
      ProfileStorageService.profileStorageKey
    );

    this.storageService.set(ProfileStorageService.profileStorageKey, {
      ...currentProfile,
      ...profile,
    });

    this.changeIsLoggedInWithToken();
    this.toggleProfileIsChanged();
  }

  public getProfileStorage(): any {
    let profile;
    try {
      profile = this.storageService.get(
        ProfileStorageService.profileStorageKey
      );
    } catch (error) {
      console.error(error);
    }
    return profile;
  }

  public syncResponseMapWithProfile(): void {
    const stored = this.storageService.get(
      ProfileStorageService.profileStorageKey
    );
    if (!stored.responseMap) {
      stored.responseMap = {
        $functie: {},
        $voorkeurenDoelgroep: {},
        $voorkeurenSoortWerk: {},
        $voorkeurenWerkomstandigheden: {},
      };
    }
    stored.responseMap.$functie.data = stored.functies;
    this.storageService.set(ProfileStorageService.profileStorageKey, stored);
  }

  public getIsLoggedIn(): boolean {
    this.changeIsLoggedInWithToken();
    return this.isLoggedIn;
  }

  private changeIsLoggedInWithToken(): void {
    const newIsLoggedIn: boolean = this.checkIfIsLoggedIn();
    if (newIsLoggedIn !== this.isLoggedIn) {
      this.isLoggedIn = newIsLoggedIn;
      this.loggedInSubject.next(this.isLoggedIn);
    }
  }

  private checkIfIsLoggedIn(): boolean {
    const profile = this.getProfileStorage() || {};
    if (this.jwtHelper.isTokenExpired(profile.token)) {
      delete profile.token;
      this.storageService.set(ProfileStorageService.profileStorageKey, profile);
    }

    return profile.token !== undefined;
  }

  public hasTokenExpired(): boolean | Promise<boolean> {
    const profile = this.getProfileStorage() || {};
    return this.jwtHelper.isTokenExpired(profile.token);
  }

  private toggleProfileIsChanged(): void {
    this.profileIsChangedSubject.next();
  }
}
