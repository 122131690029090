import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  ChangeAccountRequest,
  GetAccountResponse,
  LoginRequestModel,
  LoginResponseModel,
  PasswordChangeRequest,
  RegisterAccountRequest
} from '../../model/account/account';

@Injectable({
  providedIn: 'root'
})
export class AccountResource {
  public constructor(private httpClient: HttpClient) {
  }

  public getAccount(): Observable<GetAccountResponse> {
    return this.httpClient.get<GetAccountResponse>('/api/account');
  }

  public postCreate(createAccountModel: RegisterAccountRequest): Observable<LoginResponseModel> {
    return this.httpClient.post<LoginResponseModel>('/api/account', createAccountModel);
  }

  public postLogin(loginData: LoginRequestModel): Observable<LoginResponseModel> {
    return this.httpClient.post<LoginResponseModel>('/api/account/login', loginData);
  }

  public getLogout(): Observable<HttpResponse<any>> {
    return this.httpClient.get<HttpResponse<any>>('/api/account/logout');
  }

  public postPasswordReset(username: string): Observable<LoginResponseModel> {
    return this.httpClient.post<LoginResponseModel>('/api/account/password/reset', { username });
  }

  public postPasswordChange(passwordChangeRequest: PasswordChangeRequest): Observable<LoginResponseModel> {
    return this.httpClient.post<LoginResponseModel>('/api/account/password/change', passwordChangeRequest);
  }

  public putAccount(accountChangedRequest: ChangeAccountRequest): Observable<string> {
    return this.httpClient.put<string>('/api/account', accountChangedRequest);
  }
}
