import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { ProfileStorageService } from '../profile/profile-storage.service';
import { newGuid } from '../../model/guid/Guid';
import { catchError } from 'rxjs/operators';
import { ProfielResource } from './profiel.resource';
import { ExcludedCall } from '../../model/http/ExcludedCall';
import { HttpMethodEnum } from '../../model/http/HttpMethodEnum';
import { MatDialog } from "@angular/material/dialog";
import { UtilService } from "../util/util.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ProfileInterceptorService implements HttpInterceptor {
  static excludedPaths: ExcludedCall[] = [
    new ExcludedCall('/api/profile', HttpMethodEnum.PUT),
    new ExcludedCall('/api/account', HttpMethodEnum.POST),
    new ExcludedCall('/api/account', HttpMethodEnum.DELETE),
    new ExcludedCall('/api/account', HttpMethodEnum.PUT)
  ];

  public subscriptions: Subscription[] = [];
  public screenMode;
  public isMobile;

  public constructor(
    private utilService: UtilService,
    private profileStorageService: ProfileStorageService,
    private router: Router,
    private matDialog: MatDialog,
    private profileResource: ProfielResource
  ) {
    this.subscriptions.push(
      utilService.getScreenMode$().subscribe((screenMode: any) => {
        this.screenMode = screenMode;
        this.isMobile = screenMode.includes('MOBILE');
      })
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('/api/') === 0 || req.url.indexOf('/testengine/') === 0) {
      let profile = this.profileStorageService.getProfileStorage();

      if (!profile || !profile.correlationId) {
        profile = { ...profile, correlationId: newGuid() };
        this.profileStorageService.saveProfileInStorage(profile);
      }

      const newRequest = profile.token && !this.profileStorageService.hasTokenExpired()
        ? req.clone({
            setHeaders: { correlationId: profile.correlationId, Authorization: `Bearer ${profile.token}` }
          })
        : req.clone({
            setHeaders: { correlationId: profile.correlationId }
          });

      return next.handle(newRequest).pipe(
        catchError((error: HttpErrorResponse) => {
          const isExcluded =
            typeof ProfileInterceptorService.excludedPaths.find((ep: ExcludedCall) =>
              ep.matches(req.url, req.method)
            ) !== 'undefined';

          if (!isExcluded && error.status === 401) {
            profile = { correlationId: newGuid() };
            this.profileStorageService.deleteProfileStorage();
            this.profileStorageService.saveProfileInStorage(profile);
            this.profileResource.updateProfiel(profile).subscribe();
          }

          return throwError(error);
        })
      );
    }
  }
}
