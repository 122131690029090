import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {MatchTestResult, Profiel} from '../../model/profiel/profiel';
import {RekenhulpResponse} from '../../model/rekenhulp/RekenHulpRegel';
import {SurveyResource} from "./survey.resource";
import {map, switchMap} from "rxjs/operators";
import {TestResult} from "../../../types/app-types";

const api = '/api/';

@Injectable({
  providedIn: 'root'
})
export class ProfielResource {
  public constructor(private httpClient: HttpClient, private surveyResource: SurveyResource) {
  }

  public getVergelijkingMetFunctie(functieId: number, profiel: any): Observable<any> {
    return this.httpClient.post(`${api}profiel/vergelijk-functie/${functieId}`, profiel);
  }

  public getProfiel(geefVerrijktProfiel: boolean = false): Observable<Profiel> {
    return this.httpClient.get<Profiel>(`${api}profiel?verrijkteProfielView=${geefVerrijktProfiel}`);
  }

  public updateProfiel(profiel: Profiel): Observable<any> {
    return this.httpClient.put<Profiel>(`${api}profiel`, profiel);
  }

  public getBigRekenhulpRegels(selectedIds: number[]): Observable<RekenhulpResponse> {
    return this.httpClient.post<RekenhulpResponse>(`${api}profiel/bereken-big-uren`, selectedIds);
  }

  public getProfielWithCompletedSurveysAndTestResultsPreferences(): Observable<Profiel>{
    return forkJoin(
      {
        profile: this.getProfiel(),
        completedSurveys: this.surveyResource.getCompletedSurveys()
      }).pipe(
        map(results => {
          const profile = results.profile;
          profile.afgerondeTesten = results.completedSurveys.filter(dialog => dialog.status === 'FINISHED');
          return profile;
        })
    ).pipe(
      switchMap(profile => {
        if (profile.afgerondeTesten && profile.afgerondeTesten.filter(dialog => dialog.survey.usableForFunctionMatch).length > 0){
          return this.getProfileTestResultPreferences(profile.afgerondeTesten.map(dialog => dialog.id)).pipe(
            map(testResults => {
              profile.werkvermogens = testResults.werkvermogens;
              profile.persoonlijkeEigenschappen = testResults.persoonlijkeEigenschappen;
              return profile;
            })
          );
        } else {
          return of(profile)
        }
      })
    )
  }

  public getProfileTestResultPreferences(selectedDialogs: number[]) : Observable<MatchTestResult>{
    return forkJoin(selectedDialogs.map(dialogId => this.surveyResource.getTestResult(dialogId))).pipe(
      switchMap(testResults => this.matchTestResultaten(testResults)))
  }

  private matchTestResultaten(testResultaten: TestResult[]): Observable<MatchTestResult> {
    return this.httpClient.post<MatchTestResult>('/api/test-results-matcher', testResultaten);
  }

  public getProfielWithCompetentieClusters(geefVerrijktProfiel: boolean = false, functies: any[] = [], opleidingen: any[] = []): Observable<any> {
    const params: any = {};
    if (functies.length > 0) {
      params['functies'] = functies.toString();
    }
    if (opleidingen.length > 0) {
      params['opleidingen'] = opleidingen.toString();
    }
    return this.httpClient.get<Profiel>(`${api}profiel?verrijkteProfielView=${geefVerrijktProfiel}`, { params: params });
  }
}
