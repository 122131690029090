import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  private _state: any = {};
  public state$: BehaviorSubject<any> = new BehaviorSubject<any>(this._state);

  public addStateItem(key, value): void {
    this._state[key] = value;
    this.state$.next(this._state);
  }

  public removeStateItem(key): void {
    this._state[key] = undefined;
    this.state$.next(this._state);
  }
}
