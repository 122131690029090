<button class="button"
        [disabled]="!enabled"
        (click)="clicked.emit()"
        [type]="type"
        [ngClass]="{
            'button--primary': mode === 'primary',
            'button--secondary': mode === 'secondary',
            'button--tertiary': mode === 'tertiary',
            'button--testrunner-secondary': mode === 'testrunner-secondary',
            'button--testrunner-tertiary': mode === 'testrunner-tertiary',
            'button--testrunner-secondary-mobile': mode === 'testrunner-secondary-mobile',
            'button--testrunner-tertiary-mobile': mode === 'testrunner-tertiary-mobile',
            'button--facebook': mode === 'facebook',
            'button--linkedin': mode === 'linkedin',
            'button--full-width': fullWidth,
            'rounded': rounded
            }">
  <ng-content></ng-content>
</button>
