import {NgModule} from '@angular/core';
import {LoginModalComponent} from './login-modal/login-modal.component';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../../app.material.module';
import {RouterModule} from '@angular/router';
import {ContentModule} from '../../services/content/content.module';
import {RegisterFormComponent} from './register-form/register-form.component';
import {InputModule} from '../input/input.module';
import {AgreementModalComponent} from './agreement-modal/agreement-modal.component';
import {PasswordForgottenFormComponent} from './password-forgotten-form/password-forgotten-form.component';
import {PasswordChangeFormComponent} from './password-change-form/password-change-form.component';
import {ButtonModule} from '../button/button.module';
import {SocialLoginComponent} from './social-login/social-login.component';

@NgModule({
    declarations: [
        LoginModalComponent,
        RegisterFormComponent,
        AgreementModalComponent,
        PasswordForgottenFormComponent,
        PasswordChangeFormComponent,
        SocialLoginComponent
    ],
    imports: [CommonModule, RouterModule, MaterialModule, ReactiveFormsModule, ContentModule, InputModule, ButtonModule],
    exports: [
        LoginModalComponent,
        RegisterFormComponent,
        AgreementModalComponent,
        PasswordForgottenFormComponent,
        PasswordChangeFormComponent,
        SocialLoginComponent
    ]
})
export class LoginModule {}
