import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public set(key: string, value: any, session: boolean = false): void {
    !session ? localStorage.setItem(key, JSON.stringify(value)) : sessionStorage.setItem(key, JSON.stringify(value));
  }

  public delete(key: string, session: boolean = false): void {
    !session ? localStorage.removeItem(key) : sessionStorage.removeItem(key);
  }

  public get(key: string, session: boolean = false): any {
    return JSON.parse(!session ? localStorage.getItem(key) : sessionStorage.getItem(key));
  }
}
