<div>
  <div class="about__container" *ngIf="cmsContent">
    <div class="about__search-container">
      <h1>{{ cmsContent.title }}</h1>
    </div>
    <div class="about__container--background-image">
      <img
        src="/assets/images/018222.2362 - Zorginspirator.nl -  headers 2600x1200 sRGB - Over - Contact - Downloads.jpg"
        alt="Zorginspirator"
      />
      <div class="about__container--background-image-overlay"></div>
    </div>
  </div>
  <div *ngIf="cmsContent" class="about">
    <p class="about_introduction">{{ cmsContent.introduction }}</p>

    <!--  <div class="about__movie-container" [innerHTML]="cmsContent.movie | sanitize: 'html'"></div>-->

    <div [innerHTML]="cmsContent.body"></div>
  </div>
</div>
