import { HttpParameterCodec, HttpParams } from '@angular/common/http';

// Solves the encoding of the + sign, see https://github.com/angular/angular/issues/18261
class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export function convertToHttpParams(requestOptions: Object): HttpParams {
  let params = new HttpParams({ encoder: new CustomEncoder() });
  Object.keys(requestOptions).forEach(key => {
    const value = requestOptions[key];
    if (Array.isArray(value)) {
      value.forEach(subValue => (params = params.append(key, subValue)));
    } else {
      params = params.set(key, value);
    }
  });
  return params;
}
