import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../app.material.module';
import { RouterModule } from '@angular/router';
import { LoginModule } from '../account/login.module';
import { ContentModule } from '../../services/content/content.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, MaterialModule, RouterModule, LoginModule, ContentModule, ButtonModule],
  exports: [HeaderComponent]
})
export class HeaderModule {}
