import { Pipe, PipeTransform } from '@angular/core';
import { ContentService } from './content.service';

@Pipe({
  name: 'content',
  pure: true
})
export class ContentPipe implements PipeTransform {
  public constructor(private contentService: ContentService) {}

  public transform(key: string, replacements?: any): string {
    return this.contentService.getContent(key, replacements);
  }
}
