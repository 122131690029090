import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ContentService } from '../../services/content/content.service';

const content = require('./ie-warning-modal.content.json');

@Component({
  selector: 'app-ie-warning',
  templateUrl: './ie-warning-modal.component.html',
  styleUrls: ['./ie-warning-modal.component.scss']
})
export class IeWarningModalComponent {
  public constructor(
    private matDialogRef: MatDialogRef<IeWarningModalComponent>,
    private contentService: ContentService
  ) {
    contentService.loadContent(content);
  }

  public closeModal(): void {
    this.matDialogRef.close();
  }
}
