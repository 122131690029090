import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser'

@Component({
  selector: 'app-page-not-found',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(private titleService: Title) { }

  public ngOnInit() {
    this.titleService.setTitle('404: Deze pagina kan niet gevonden worden')
  }
}
