import { NgModule } from '@angular/core';
import { ShareComponent } from './share.component';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../app.material.module';

@NgModule({
  declarations: [ShareComponent],
  imports: [CommonModule, MaterialModule],
  exports: [ShareComponent]
})
export class ShareModule {}
