import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ScreenMode } from './screen-mode.enum';
import {
  DESKTOP_LARGE_WIDTH,
  DESKTOP_MEDIUM_WIDTH,
  DESKTOP_SMALL_WIDTH,
  MOBILE_LARGE_WIDTH,
  MOBILE_MEDIUM_WIDTH,
  MOBILE_SMALL_WIDTH
} from './screen-mode.constant';
import { filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilService implements OnDestroy {
  public screenMode$: BehaviorSubject<string> = new BehaviorSubject<string>('desktop');
  public direction$: BehaviorSubject<string> = new BehaviorSubject<string>('up');
  public firstRoute$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public subscriptions: Subscription[] = [];

  public constructor(private ngZone: NgZone, private router: Router) {
    this.calculateScreenMode();
    window.onresize = () => {
      this.calculateScreenMode();
    };

    window.addEventListener('scroll', () => {
      if (window.scrollY === 0) {
        this.direction$.next('up');
      } else {
        this.direction$.next('down');
      }
    });

    this.subscriptions.push(
      router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        this.firstRoute$.next(event.id === 1);
      })
    );
  }

  public getScreenMode$(): BehaviorSubject<string> {
    return this.screenMode$;
  }

  private calculateScreenMode(): void {
    this.ngZone.run(() => {
      if (window.innerWidth <= MOBILE_SMALL_WIDTH) {
        this.screenMode$.next(ScreenMode.MOBILE_SMALL);
      } else if (window.innerWidth <= MOBILE_MEDIUM_WIDTH) {
        this.screenMode$.next(ScreenMode.MOBILE_MEDIUM);
      } else if (window.innerWidth <= MOBILE_LARGE_WIDTH) {
        this.screenMode$.next(ScreenMode.MOBILE_LARGE);
      } else if (window.innerWidth <= DESKTOP_SMALL_WIDTH) {
        this.screenMode$.next(ScreenMode.DESKTOP_SMALL);
      } else if (window.innerWidth <= DESKTOP_MEDIUM_WIDTH) {
        this.screenMode$.next(ScreenMode.DESKTOP_MEDIUM);
      } else if (window.innerWidth <= DESKTOP_LARGE_WIDTH) {
        this.screenMode$.next(ScreenMode.DESKTOP_LARGE);
      } else {
        this.screenMode$.next(ScreenMode.DESKTOP_EXTRA_LARGE);
      }
    });
  }

  public getDirection$(): BehaviorSubject<string> {
    return this.direction$;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
