// src/app/auth/auth-guard.service.ts
import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRoute} from '@angular/router';
import {ProfileStorageService} from "./profile/profile-storage.service";
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {UtilService} from "./util/util.service";
import {AccountResource} from "./resources/account.resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ContentService} from "./content/content.service";

@Injectable()
export class AuthGuardService implements CanActivate {
  public subscriptions: Subscription[] = [];
  public screenMode;
  public isMobile;
  public isRestricted;

  constructor(
    private utilService: UtilService,
    private profileStorage: ProfileStorageService,
    private accountService: AccountResource,
    private matSnackBar: MatSnackBar,
    private router: Router,
    private matDialog: MatDialog,
    private contentService: ContentService,
    private route: ActivatedRoute
  ) {
    this.subscriptions.push(
      utilService.getScreenMode$().subscribe((screenMode: any) => {
        this.screenMode = screenMode;
        this.isMobile = screenMode.includes('MOBILE');
      })
    );

    this.isRestricted = route.snapshot.data.isRestricted;
  }
  canActivate(): Promise<boolean> {
    const profile = this.profileStorage.getProfileStorage();

    // for some routes access is limited to logged in users only
    // for all other routes the api fails if the token has timed out, so
    // if the user has an expired token, the user needs to log in again
    if (this.isRestricted && !this.profileStorage.getIsLoggedIn() ||
      profile?.token && this.profileStorage.hasTokenExpired()) {
      this.logout();
    }
    return Promise.resolve(true);
  }

  public logout(): void {
    this.profileStorage.deleteProfileStorage();
    this.router.navigate(['/']);
    this.accountService
      .getLogout()
      .subscribe(() =>
        this.matSnackBar.open(this.contentService.getContent('navigate.signed-out-message'), '', { duration: 5000 })
      );
  }
}
