import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  private switch = environment.contentSwitch;
  private content: any = {};

  public loadContent(content: any): void {
    Object.keys(content).forEach(item => (this.content[item] = content[item]));
  }

  public getContent(key: string, replacements?: any): string {
    const rawString = this.content[key][this.switch];

    if (replacements && rawString) {
      return rawString.replace(/%\w+%/g, all => {
        const sanitizedAll = all.replace(/% ?/g, '');

        return replacements[sanitizedAll] + '' || all;
      });
    }

    return rawString;
  }
}
