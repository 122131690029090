import { NgModule } from '@angular/core';
import { ContentPipe } from './content.pipe';
import { ContentService } from './content.service';

@NgModule({
  declarations: [ContentPipe],
  exports: [ContentPipe],
  providers: [ContentService]
})
export class ContentModule {}
