<div
  class="header-horizontal"
  [ngClass]="{
    minimized: showNavigation || direction === 'down' || isSmallerMobile
  }"
  [ngStyle]="{
    'max-width.px': isChatFlow
      ? null
      : (isMinimized
          ? verticalHeaderMinimizedWidth
          : verticalHeaderMaximizedWidth) +
        (screenMode !== 'DESKTOP_EXTRA_LARGE' && !isMinimized
          ? contentWithOverflowHeaderWidth
          : contentMaxWidth)
  }"
>
  <div class="header-horizontal__flex-wrapper">
    <div
      class="header-horizontal__hamburger menu-icon-wrapper"
      *ngIf="screenMode !== 'DESKTOP_EXTRA_LARGE'"
      (click)="handleHamburgerClick()"
    >
      <div class="menu-icon" [ngClass]="{ 'menu-icon--open': !isMinimized }">
        <span class="menu-icon__item"></span>
        <span class="menu-icon__item"></span>
        <span class="menu-icon__item"></span>
        <span class="menu-icon__item"></span>
      </div>
    </div>

    <a routerLink="/">
      <img
        [ngClass]="{
          'header-horizontal__logo--minimized':
            showNavigation || direction === 'down' || isSmallerMobile
        }"
        class="header-horizontal__logo"
        src="assets/logo.svg"
        alt="zorg inspirator"
      />
    </a>

    <button
      *ngIf="isChatFlow"
      (click)="goBack()"
      class="header-horizontal__chatter-icon"
      data-cy="chatbotSluiten"
    >
      <mat-icon class="material-icons header-horizontal__chatter-close">
        close
      </mat-icon>
    </button>

    <div *ngIf="!isChatFlow" class="header-horizontal__login-logout">
      <p class="header-horizontal__login-logout--username" *ngIf="isLoggedIn">
        {{ userFirstName }}
      </p>
      <app-button
        class="header-horizontal__login-logout--button"
        mode="secondary"
        [fullWidth]="false"
        (clicked)="handleAuthentication()"
      >
        <mat-icon>input</mat-icon>
        {{
          isLoggedIn
            ? ("header.menu-sign-out" | content)
            : ("header.menu-sign-in" | content)
        }}
      </app-button>
    </div>
  </div>
</div>
