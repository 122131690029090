import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AnalyticsService {
  public constructor(private _router: Router) {}

  public pageView(overridePath?: string): void {
    (<any>window).dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: overridePath || this._router.url,
      virtualPageTitle: overridePath || this._router.url
    });
  }

  public event(eventName: string, eventData = null): void {
    (<any>window).dataLayer.push({
      event: eventName,
      ...eventData
    });
  }
}
