<div class="ie-warning">
  <div class="ie-warning__header">
    <h1>{{'ie-warning.header' | content}}</h1>
    <button mat-icon-button (click)="closeModal()">
      <mat-icon aria-label="Sluiten sluit icon">close</mat-icon>
    </button>
  </div>
  <div class="ie-warning__content">
    <p>{{'ie-warning.content' | content}}</p>
    <div class="ie-warning__browsers">
      <a target="_blank" rel="noopener" href="https://www.google.com/intl/nl_nl/chrome/">
        <img alt="chrome" src="../../../assets/images/browsers/chrome.svg" />
        Chrome
      </a>
      <a target="_blank" rel="noopener" href="https://www.microsoft.com/nl-nl/windows/microsoft-edge">
        <img alt="edge" src="../../../assets/images/browsers/edge.svg" />
        Edge
      </a>
      <a target="_blank" rel="noopener" href="https://www.mozilla.org/nl/firefox/new/">
        <img alt="firefox" src="../../../assets/images/browsers/firefoxy.svg" />
        Firefox
      </a>
    </div>
  </div>
</div>
